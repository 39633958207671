import { User } from "firebase/auth";

import { getFirebaseAuth } from "./getFirebaseAuth";

export const getFirebaseUserAsync = (): Promise<User | null> => {
  return new Promise<User | null>((resolve) => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged((user) => {
      resolve(user);

      unsubscribe();
    });
  });
};
