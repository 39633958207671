import { PatientMedicineNotebookShare } from "@mgdx/api/lib/patient/patient";
import { PharmacyChain } from "@mgdx/api/lib/pharmacy/pharmacy";
import {
  MedicineNotebookAccount,
  MedicineNotebookFamilyAccount,
  MedicineNotebookSharedAccount,
} from "@mgdx/shared/src/models/MedicineNotebook";
import { fullName, Patient } from "@mgdx/shared/src/models/Patient";
import { OptionProps } from "@mgdx/ui/components/InputSelectField";
import { logger } from "@mgdx-libs/logger";
import React, { createContext, useContext, useMemo } from "react";

export const MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID = "parent" as const;

export const MEDICINE_NOTEBOOK_FAMILY_ACCOUNT_ID_PREFIX = "family" as const;

export const MEDICINE_NOTEBOOK_SHARED_ACCOUNT_ID_PREFIX = "shared" as const;

const initialMedicineNotebookAccount: MedicineNotebookAccount = {
  accountId: "",
  accountType: "",
  familyAccount: null,
  familyAccountId: "",
  hasMedicineNotebook: false,
  patient: null,
  sharedPatient: null,
  sharedFamilyAccount: null,
};

export type MedicineNotebookAccountRequestConfig =
  | {
      // Patient or FamilyAccount
      chainId: number;
      familyAccountId: number | undefined;
      sharedPatientId: undefined;
      sharedFamilyAccountId: undefined;
      shouldUseSharedApi: false;
      accountKeys: {
        chainId: number;
        familyAccountId: number | undefined;
      };
    }
  | {
      // Shared Patient or Shared FamilyAccount
      chainId: number;
      familyAccountId: undefined;
      sharedPatientId: number;
      sharedFamilyAccountId: number | undefined;
      shouldUseSharedApi: true;
      accountKeys: {
        chainId: number;
        patientId: number;
        familyAccountId: number | undefined;
      };
    };

const initialMedicineNotebookAccountRequestConfig: MedicineNotebookAccountRequestConfig = {
  chainId: 0,
  familyAccountId: undefined,
  sharedPatientId: undefined,
  sharedFamilyAccountId: undefined,
  shouldUseSharedApi: false,
  accountKeys: {
    chainId: 0,
    familyAccountId: undefined,
  },
};

export const getMedicineNotebookAccountRequestConfig = () => {
  return {
    ...initialMedicineNotebookAccountRequestConfig,
  };
};

type MedicineNotebookAccountContextValue = {
  accountId: string;
  accountOptions: OptionProps[];
  chainName: string;
  familyAccounts: MedicineNotebookFamilyAccount[];
  hasMasterLicense: boolean;
  patient?: Patient;
  account: MedicineNotebookAccount;
  requestConfig: MedicineNotebookAccountRequestConfig;
  isSharedAccount: boolean;
};

const MedicineNotebookAccountContext = createContext<MedicineNotebookAccountContextValue>({
  chainName: "",
  accountId: MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID,
  accountOptions: [],
  familyAccounts: [],
  hasMasterLicense: false,
  patient: undefined,
  account: initialMedicineNotebookAccount,
  requestConfig: initialMedicineNotebookAccountRequestConfig,
  isSharedAccount: false,
});

export type MedicineNotebookAccountProviderProps = React.PropsWithChildren<{
  accountId: string;
  chain: PharmacyChain | undefined;
  medicineNotebookShares: PatientMedicineNotebookShare[];
  patient: Patient | undefined;
}>;

export const MedicineNotebookAccountProvider: React.FC<MedicineNotebookAccountProviderProps> = ({
  chain,
  children,
  medicineNotebookShares,
  patient,
  ...props
}) => {
  const chainId = useMemo(() => chain?.id || 0, [chain?.id]);

  const chainName = useMemo(() => chain?.name || "", [chain?.name]);

  const hasMasterLicense = useMemo(
    () => !!chain?.medicineNotebookClient?.hasMasterLicense,
    [chain?.medicineNotebookClient?.hasMasterLicense]
  );

  const familyAccounts = useMemo<MedicineNotebookFamilyAccount[]>(
    () =>
      (patient?.familyAccount || []).map((familyAccount) => ({
        ...familyAccount,
        accountId: `${MEDICINE_NOTEBOOK_FAMILY_ACCOUNT_ID_PREFIX}-${familyAccount.id}`,
      })),
    [patient?.familyAccount]
  );

  const sharedAccounts = useMemo<MedicineNotebookSharedAccount[]>(() => {
    const accounts: MedicineNotebookSharedAccount[] = [];

    medicineNotebookShares.forEach((medicineNotebookShare) => {
      if (medicineNotebookShare.scope.patient) {
        accounts.push({
          accountId: `${MEDICINE_NOTEBOOK_SHARED_ACCOUNT_ID_PREFIX}-${medicineNotebookShare.scope.patient.id}-0`,
          accountType: "patient",
          patient: medicineNotebookShare.scope.patient,
          familyAccount: undefined,
        });
      }

      if (medicineNotebookShare.scope.familyAccounts) {
        medicineNotebookShare.scope.familyAccounts.forEach((familyAccount) => {
          accounts.push({
            accountId: `${MEDICINE_NOTEBOOK_SHARED_ACCOUNT_ID_PREFIX}-${medicineNotebookShare.fromPatient.id}-${familyAccount.id}`,
            accountType: "familyAccount",
            patient: medicineNotebookShare.fromPatient,
            familyAccount,
          });
        });
      }
    });

    return accounts;
  }, [medicineNotebookShares]);

  const accountOptions = useMemo<MedicineNotebookAccountContextValue["accountOptions"]>(() => {
    if (!patient) return [];

    const options: MedicineNotebookAccountContextValue["accountOptions"] = [
      {
        label: fullName(patient),
        value: MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID,
      },
    ];

    familyAccounts.forEach((familyAccount) => {
      options.push({
        label: fullName(familyAccount),
        value: familyAccount.accountId,
      });
    });

    sharedAccounts.forEach((sharedAccount) => {
      if (sharedAccount.accountType === "patient") {
        options.push({
          label: `${fullName(sharedAccount.patient)} (共有)`,
          value: sharedAccount.accountId,
        });
      }

      if (sharedAccount.accountType === "familyAccount") {
        options.push({
          label: `${fullName(sharedAccount.familyAccount)} (共有)`,
          value: sharedAccount.accountId,
        });
      }
    });

    return options;
  }, [familyAccounts, patient, sharedAccounts]);

  const isValidAccountId = useMemo<boolean>(
    () => !!accountOptions.find((option) => option.value === props.accountId),
    [accountOptions, props.accountId]
  );

  const accountId = useMemo<string>(
    () => (isValidAccountId ? props.accountId : MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID),
    [isValidAccountId, props.accountId]
  );

  const account = useMemo<MedicineNotebookAccount>(() => {
    if (!patient) return initialMedicineNotebookAccount;
    if (!chainId) return initialMedicineNotebookAccount;

    if (accountId === MEDICINE_NOTEBOOK_DEFAULT_ACCOUNT_ID) {
      const medicineNotebook = patient.medicineNotebooks?.find(
        (medicineNotebook) => medicineNotebook.chainId === chainId
      );

      if (medicineNotebook) {
        const newAccount: MedicineNotebookAccount = {
          accountId,
          accountType: "patient",
          familyAccount: null,
          familyAccountId: "",
          hasMedicineNotebook: true,
          patient,
          sharedPatient: null,
          sharedFamilyAccount: null,
        };

        logger.debug("MedicineNotebookAccountProvider Change account: %o", newAccount);

        return newAccount;
      }

      logger.error("MedicineNotebookAccountProvider Change account: Not have medicine notebook account");
      return initialMedicineNotebookAccount;
    }

    const familyAccount = familyAccounts.find((familyAccount) => familyAccount.accountId === accountId);
    if (familyAccount) {
      const medicineNotebook = familyAccount.medicineNotebooks?.find(
        (medicineNotebook) => medicineNotebook.chainId === chainId
      );

      const newAccount: MedicineNotebookAccount = {
        accountId,
        accountType: "familyAccount",
        familyAccount,
        familyAccountId: familyAccount.id.toString(),
        hasMedicineNotebook: !!medicineNotebook,
        patient: null,
        sharedPatient: null,
        sharedFamilyAccount: null,
      };

      logger.debug("MedicineNotebookAccountProvider Change account: %o", newAccount);

      return newAccount;
    }

    const sharedAccount = sharedAccounts.find((sharedAccount) => sharedAccount.accountId === accountId);
    if (sharedAccount) {
      const newAccount: MedicineNotebookAccount = {
        accountId,
        accountType: "sharedAccount",
        familyAccount: null,
        familyAccountId: "",
        hasMedicineNotebook: true,
        patient: null,
        sharedPatient: sharedAccount.patient,
        sharedFamilyAccount: sharedAccount.familyAccount || null,
      };

      logger.debug("MedicineNotebookAccountProvider Change account: %o", newAccount);

      return newAccount;
    }

    logger.error("MedicineNotebookAccountProvider Change account: Not found medicine notebook account");
    return initialMedicineNotebookAccount;
  }, [accountId, chainId, familyAccounts, patient, sharedAccounts]);

  const isSharedAccount = useMemo<boolean>(() => account.accountType === "sharedAccount", [account.accountType]);

  const requestConfig = useMemo<MedicineNotebookAccountRequestConfig>(() => {
    if (account.accountType === "familyAccount") {
      const familyAccount = account.familyAccount;

      return {
        chainId,
        familyAccountId: familyAccount.id,
        sharedPatientId: undefined,
        sharedFamilyAccountId: undefined,
        shouldUseSharedApi: false,
        accountKeys: {
          chainId,
          familyAccountId: familyAccount.id,
        },
      };
    }

    if (account.accountType === "sharedAccount") {
      const sharedPatient = account.sharedPatient;
      const sharedFamilyAccount = account.sharedFamilyAccount;

      return {
        chainId,
        familyAccountId: undefined,
        sharedPatientId: sharedPatient.id,
        sharedFamilyAccountId: sharedFamilyAccount?.id,
        shouldUseSharedApi: true,
        accountKeys: {
          chainId,
          patientId: sharedPatient.id,
          familyAccountId: sharedFamilyAccount?.id,
        },
      };
    }

    return {
      chainId,
      familyAccountId: undefined,
      sharedPatientId: undefined,
      sharedFamilyAccountId: undefined,
      shouldUseSharedApi: false,
      accountKeys: {
        chainId,
        familyAccountId: undefined,
      },
    };
  }, [account.accountType, account.familyAccount, account.sharedFamilyAccount, account.sharedPatient, chainId]);

  const value = useMemo<MedicineNotebookAccountContextValue>(
    () => ({
      account,
      accountId,
      accountOptions,
      chainName,
      familyAccounts,
      hasMasterLicense,
      patient,
      requestConfig,
      isSharedAccount,
    }),
    [
      account,
      accountId,
      accountOptions,
      chainName,
      familyAccounts,
      hasMasterLicense,
      isSharedAccount,
      patient,
      requestConfig,
    ]
  );

  return <MedicineNotebookAccountContext.Provider value={value}>{children}</MedicineNotebookAccountContext.Provider>;
};

export const useMedicineNotebookAccount = (): MedicineNotebookAccountContextValue =>
  useContext(MedicineNotebookAccountContext);
