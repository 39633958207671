import { FamilyAccount, Patient, PatientStatusEnum } from "@mgdx/shared/src/models/Patient";
import { enableTalk } from "@mgdx/talk/ducks";
import { createAction, createReducer, Dispatch } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

export type CurrentUserState = {
  userId: number;
  userFirstName: string;
  userLastName: string;
  userInfo?: Patient;
  isEmailVerified: boolean;
  isLoggedIn: boolean;
  isLoggedInAndValidAccessToken: boolean;
  isRegistered: boolean;
  inProcessOfSignup: boolean;
  hasMedicineNotebookAccount: boolean;
  isLiffLogined: boolean;
  isInLineApp: boolean;
  isLoginChecked: boolean;
  hasInterviewSheetSetting: boolean;
  favoritePharmacyIds: number[];
  needsShowMigrateMedicineNotebook: boolean;
};
export const initialState: CurrentUserState = {
  userId: 0,
  userFirstName: "",
  userLastName: "",
  userInfo: undefined,
  isEmailVerified: false,
  isLiffLogined: false,
  isLoggedIn: false,
  isLoggedInAndValidAccessToken: false,
  isRegistered: false,
  inProcessOfSignup: false,
  hasMedicineNotebookAccount: false,
  isLoginChecked: true,
  isInLineApp: false,
  hasInterviewSheetSetting: false,
  favoritePharmacyIds: [],
  needsShowMigrateMedicineNotebook: false,
};

export const setCurrentUser = createAction<Patient>("currentUser/setCurrentUser");
export const setLiffLogined = createAction<boolean>("currentUser/setLiffLogined");
export const setInLineApp = createAction<boolean>("currentUser/setInLineApp");
export const setInProcessOfSignup = createAction<boolean>("currentUser/setInProcessOfSignup");
export const clearCurrentUser = createAction("currentUser/clearCurrentUser");
export const setFavoritePharmacyIds = createAction<CurrentUserState["favoritePharmacyIds"]>(
  "currentUser/setFavoritePharmacyIds"
);
export const optimisticallyAddFamilyAccount = createAction<FamilyAccount>(
  "currentUser/optimisticallyUpdateFamilyAccounts"
);
export const setNeedsShowMigrateMedicineNotebook = createAction<boolean>(
  "currentUser/setNeedsShowMigrateMedicineNotebook"
);

export const setCurrentUserWithDispatch = (dispatch: Dispatch, patient: Patient) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: patient.id.toString(),
    });
  });
  dispatch(setCurrentUser(patient));
  dispatch(enableTalk());
};

const currentUserReducer = createReducer<CurrentUserState>({ ...initialState, isLoginChecked: false }, (builder) => {
  builder
    .addCase(setCurrentUser, (state, action) => {
      state.userId = action.payload.id;
      state.userFirstName = action.payload.firstName ?? "";
      state.userLastName = action.payload.lastName ?? "";
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      };
      state.isLoggedIn = true;
      state.isLoggedInAndValidAccessToken = true;
      state.isRegistered =
        action.payload.status === PatientStatusEnum.Registered || action.payload.status === PatientStatusEnum.Verified;
      state.hasMedicineNotebookAccount = (action.payload.medicineNotebooks || []).length > 0; // FIXME= 同一アカウント横断でのOEMアプリの利用の際は修正が必要
      state.isLoginChecked = true;
      state.hasInterviewSheetSetting = Boolean(action.payload.wantsGeneric || action.payload.hasMedicineNotebook);
      state.isEmailVerified = action.payload.status === PatientStatusEnum.Verified;
      state.favoritePharmacyIds = action.payload.favoritePharmacies || [];
    })
    .addCase(setInProcessOfSignup, (state, action) => {
      state.inProcessOfSignup = action.payload;
    })
    .addCase(setLiffLogined, (state, action) => {
      state.isLiffLogined = action.payload;
    })
    .addCase(setInLineApp, (state, action) => {
      state.isInLineApp = action.payload;
    })
    .addCase(setNeedsShowMigrateMedicineNotebook, (state, action) => {
      state.needsShowMigrateMedicineNotebook = action.payload;
    })
    .addCase(clearCurrentUser, (state, action) => {
      return initialState;
    })
    .addCase(setFavoritePharmacyIds, (state, action) => {
      state.favoritePharmacyIds = action.payload;
    })
    .addCase(optimisticallyAddFamilyAccount, (state, action) => {
      if (state.userInfo?.familyAccount) {
        state.userInfo.familyAccount = [...state.userInfo.familyAccount, action.payload];
      } else {
        state.userInfo = {
          ...(state.userInfo as Patient),
          familyAccount: [action.payload],
        };
      }
    });
});

export default currentUserReducer;
