import { PatientProviderTypeEnum } from "@mgdx/shared/src/models/Patient";
import { MediaDeviceProvider } from "@mgdx/shared/src/providers/MediaDeviceProvider";
import { VConsoleProvider } from "@mgdx/shared/src/providers/VConsoleProvider";
import { ToastContainer } from "@mgdx/ui/components/Toast";
import { ConfirmProvider } from "@mgdx/ui/providers/ConfirmProvider";
import React from "react";

import TalkMessageListApp from "./apps/TalkMessageListApp";
import { useCurrentPatientIsMedicineNotebookUsingQuery } from "./hooks/patient/useCurrentPatientIsMedicineNotebookUsingQuery";
import useCurrentUser from "./hooks/useCurrentUser";
import { getInAppView } from "./hooks/useIsInAppView";
import { AppStatusProvider } from "./providers/AppStatusProvider";
import { isInMccmApp } from "./utils/user-agent/isInMccmApp";

const App = ({ children }: React.PropsWithChildren<EmptyObject>) => {
  const isInApp = getInAppView() || isInMccmApp();

  const currentUser = useCurrentUser();

  useCurrentPatientIsMedicineNotebookUsingQuery({}, { enabled: currentUser.isLoggedInAndValidAccessToken });

  return (
    <AppStatusProvider
      isInApp={isInApp}
      isInLineApp={currentUser.isInLineApp}
      isPharmacyDispensingEnabled={process.env.DISABLE_DISPENSING !== "true"}
      isPharmacyOnlineEnabled={
        process.env.ENABLE_ONLINE_COUNSELING !== "false" &&
        ((currentUser.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineSundrug &&
          currentUser.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineQol &&
          currentUser.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineTanpopo) ||
          process.env.ENABLE_ONLINE_COUNSELING === "true")
      }
    >
      <VConsoleProvider>
        <MediaDeviceProvider>
          <ConfirmProvider>
            {children}

            <ToastContainer />
            <TalkMessageListApp />
          </ConfirmProvider>
        </MediaDeviceProvider>
      </VConsoleProvider>
    </AppStatusProvider>
  );
};

export default App;
