import { Liff } from "@liff/liff-types";
import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import { useEffect } from "react";

export type InitializeLiff = (liffId: string, liff: Liff) => void | Promise<void>;

type UseLoadLiff = (callback: InitializeLiff) => void;

const MAX_RELOAD_COUNT = 100 as const;
const RELOAD_TIMER = 100 as const;

type LoadLiff = () => Promise<Liff | undefined>;

export const loadLiff: LoadLiff = async () => {
  const checkLiffLoad = new Promise<Liff | undefined>((resolve) => {
    const liffId = process.env.LIFF_ID;
    if (liffId) {
      const liff = window["liff"] as Liff;
      if (!liff) {
        logger.error("liff is undefined");
        resolve(undefined);
      } else {
        resolve(liff);
      }
    } else {
      logger.debug("liffId is undefined");
      resolve(undefined);
    }
  });

  let fetchCount = 0;
  let result: Liff | undefined;

  while (fetchCount < MAX_RELOAD_COUNT) {
    result = await checkLiffLoad;
    if (result) {
      break;
    }
    fetchCount++;
    await new Promise((resolve, reject) => window.setTimeout(resolve, RELOAD_TIMER));
  }

  return result;
};

const useLoadLiff: UseLoadLiff = (callback: InitializeLiff) => {
  useEffect(() => {
    const liffId = process.env.LIFF_ID;
    if (liffId) {
      loadLiff()
        .then((liff) => {
          if (liff) {
            callback(liffId, liff);
          }
        })
        .catch(errorHandlerReport);
    }
  }, [callback]);
};

export default useLoadLiff;
