import { getFirebaseAuth } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import { onAuthStateChanged, User } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";

type FirebaseAuthProps = {
  user: User | null;
  isInitialized: boolean;
};
const initilaFirebaseAuthProps = {
  user: null,
  isInitialized: false,
};
const FirebaseAuthContext = createContext<FirebaseAuthProps>(initilaFirebaseAuthProps);

let isBooting = true;

const INITIALIZE_FIREBASE_AUTH_KEY = "bootingFirebaseAuth" as const;
const BOOTING_CHECK_DELAY_MS = 1000;

export type FirebaseAuthObserver = (user: User | null) => Promise<void>;

export const FirebaseAuthProvider: React.FC<{ observer: FirebaseAuthObserver; children: React.ReactNode }> = ({
  children,
  observer,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    window?.localStorage?.setItem(INITIALIZE_FIREBASE_AUTH_KEY, "true");
  }, []);

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();

    onAuthStateChanged(firebaseAuth, () => {
      const isInitializingFirebaseAuthKey = window.localStorage.getItem(INITIALIZE_FIREBASE_AUTH_KEY) !== "false";

      // このページでFirebaseの初期化が行われている場合
      if (isBooting && isInitializingFirebaseAuthKey) {
        setTimeout(() => {
          isBooting = false;
          window.localStorage.setItem(INITIALIZE_FIREBASE_AUTH_KEY, "false");
        }, BOOTING_CHECK_DELAY_MS);
      }
    });
  }, []);

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();

    onAuthStateChanged(firebaseAuth, (user) => {
      const isInitializingFirebaseAuthKey = window.localStorage.getItem(INITIALIZE_FIREBASE_AUTH_KEY) !== "false";
      const isBootingAnother = !isBooting && isInitializingFirebaseAuthKey;

      logger.debug(
        "useInitializeFirebaseAuth.onAuthStateChanged isBootingAnother: %s, user: %o",
        isBootingAnother,
        user
      );

      // 別タブなどでFirebaseの初期化が行われているためにuserがnullになった場合
      if (isBootingAnother && !user) return;

      setUser(user);
      setIsInitialized(true);
      observer(user);
    });
  }, [observer]);

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        isInitialized,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};
