/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchFamilyPharmacistRequestBody,
  PatchFamilyPharmacistRequestBodyFromJSON,
  PatchFamilyPharmacistRequestBodyToJSON,
  PatchPharmaciesRequestBody,
  PatchPharmaciesRequestBodyFromJSON,
  PatchPharmaciesRequestBodyToJSON,
  PatchPharmacyListImagesRequestBody,
  PatchPharmacyListImagesRequestBodyFromJSON,
  PatchPharmacyListImagesRequestBodyToJSON,
  PatchPharmacyListImagesResponse,
  PatchPharmacyListImagesResponseFromJSON,
  PatchPharmacyListImagesResponseToJSON,
  PharmacyCustomBadRequest,
  PharmacyCustomBadRequestFromJSON,
  PharmacyCustomBadRequestToJSON,
  PharmacyFamilyPharmacist,
  PharmacyFamilyPharmacistFromJSON,
  PharmacyFamilyPharmacistToJSON,
  PharmacyFollowUpNotificationPattern,
  PharmacyFollowUpNotificationPatternFromJSON,
  PharmacyFollowUpNotificationPatternToJSON,
  PharmacyFollowUpNotificationPatternRegistrable,
  PharmacyFollowUpNotificationPatternRegistrableFromJSON,
  PharmacyFollowUpNotificationPatternRegistrableToJSON,
  PharmacyFollowUpNotificationPatternUpdatable,
  PharmacyFollowUpNotificationPatternUpdatableFromJSON,
  PharmacyFollowUpNotificationPatternUpdatableToJSON,
  PharmacyFollowUpQuestionnairePattern,
  PharmacyFollowUpQuestionnairePatternFromJSON,
  PharmacyFollowUpQuestionnairePatternToJSON,
  PharmacyFollowUpQuestionnairePatternRegistrable,
  PharmacyFollowUpQuestionnairePatternRegistrableFromJSON,
  PharmacyFollowUpQuestionnairePatternRegistrableToJSON,
  PharmacyFollowUpQuestionnairePatternUpdatable,
  PharmacyFollowUpQuestionnairePatternUpdatableFromJSON,
  PharmacyFollowUpQuestionnairePatternUpdatableToJSON,
  PharmacyPharmacies,
  PharmacyPharmaciesFromJSON,
  PharmacyPharmaciesToJSON,
  PharmacyPharmacy,
  PharmacyPharmacyFromJSON,
  PharmacyPharmacyToJSON,
  PharmacyPharmacyBusinessHours,
  PharmacyPharmacyBusinessHoursFromJSON,
  PharmacyPharmacyBusinessHoursToJSON,
  PharmacyPharmacyBusinessHoursUpdatable,
  PharmacyPharmacyBusinessHoursUpdatableFromJSON,
  PharmacyPharmacyBusinessHoursUpdatableToJSON,
  PharmacyPharmacyCrowdStatus,
  PharmacyPharmacyCrowdStatusFromJSON,
  PharmacyPharmacyCrowdStatusToJSON,
  PharmacyPharmacyRegistrable,
  PharmacyPharmacyRegistrableFromJSON,
  PharmacyPharmacyRegistrableToJSON,
  PharmacyPharmacyUpdatable,
  PharmacyPharmacyUpdatableFromJSON,
  PharmacyPharmacyUpdatableToJSON,
  PharmacyPharmacyUsageSummary,
  PharmacyPharmacyUsageSummaryFromJSON,
  PharmacyPharmacyUsageSummaryToJSON,
  PharmacyTemporaryClosedDates,
  PharmacyTemporaryClosedDatesFromJSON,
  PharmacyTemporaryClosedDatesToJSON,
  PostFamilyPharmacistsRequestBody,
  PostFamilyPharmacistsRequestBodyFromJSON,
  PostFamilyPharmacistsRequestBodyToJSON,
  PostPharmacyListImagesRequestBody,
  PostPharmacyListImagesRequestBodyFromJSON,
  PostPharmacyListImagesRequestBodyToJSON,
  PostPharmacyListImagesResponse,
  PostPharmacyListImagesResponseFromJSON,
  PostPharmacyListImagesResponseToJSON,
  PutPharmacyLogoImageRequestBody,
  PutPharmacyLogoImageRequestBodyFromJSON,
  PutPharmacyLogoImageRequestBodyToJSON,
  PutPharmacyLogoImageResponse,
  PutPharmacyLogoImageResponseFromJSON,
  PutPharmacyLogoImageResponseToJSON,
  PutTemporaryClosedDatesRequestBody,
  PutTemporaryClosedDatesRequestBodyFromJSON,
  PutTemporaryClosedDatesRequestBodyToJSON,
} from "../models";

export interface CreatePharmacyRequest {
  pharmacyPharmacyRegistrable: Array<PharmacyPharmacyRegistrable>;
}

export interface CreatePharmacyCsvRequest {
  body: string;
}

export interface DeleteFamilyPharmacistRequest {
  familyPharmacistId: string;
}

export interface DeletePharmacyFollowUpNotificationPatternRequest {
  pharmacyId: number;
  id: number;
}

export interface DeletePharmacyFollowUpQuestionnairePatternRequest {
  pharmacyId: number;
  id: number;
}

export interface DeletePharmacyListImageRequest {
  pharmacyId: number;
  listImageId: Array<string>;
}

export interface GetFamilyPharmacistRequest {
  familyPharmacistId: string;
}

export interface GetPharmacyRequest {
  pharmacyId: number;
}

export interface GetPharmacyAllowCidrsRequest {
  pharmacyId: number;
}

export interface GetPharmacyCrowdStatusRequest {
  pharmacyId: number;
}

export interface GetPharmacyFollowUpNotificationPatternRequest {
  pharmacyId: number;
  id: number;
}

export interface GetPharmacyFollowUpNotificationPatternsRequest {
  pharmacyId: number;
}

export interface GetPharmacyFollowUpQuestionnairePatternRequest {
  pharmacyId: number;
  id: number;
}

export interface GetPharmacyFollowUpQuestionnairePatternsRequest {
  pharmacyId: number;
}

export interface GetPharmacyTemporaryClosedDatesRequest {
  pharmacyId: number;
  from: string;
  to: string;
}

export interface GetPharmacyUsageSummariesRequest {
  chainId: number;
  from: string;
  to: string;
}

export interface PatchFamilyPharmacistRequest {
  familyPharmacistId: string;
  patchFamilyPharmacistRequestBody: PatchFamilyPharmacistRequestBody;
}

export interface PatchPharmacyAllowCidrsRequest {
  pharmacyId: number;
  requestBody: Array<string>;
}

export interface PatchPharmacyOpeningHoursRequest {
  pharmacyId: number;
  pharmacyPharmacyBusinessHoursUpdatable: PharmacyPharmacyBusinessHoursUpdatable;
}

export interface PostPharmacyFollowUpNotificationPatternRequest {
  pharmacyId: number;
  pharmacyFollowUpNotificationPatternRegistrable: PharmacyFollowUpNotificationPatternRegistrable;
}

export interface PostPharmacyFollowUpQuestionnairePatternRequest {
  pharmacyId: number;
  pharmacyFollowUpQuestionnairePatternRegistrable: PharmacyFollowUpQuestionnairePatternRegistrable;
}

export interface PutPharmacyTemporaryClosedDatesRequest {
  pharmacyId: number;
  from: string;
  to: string;
  putTemporaryClosedDatesRequestBody: PutTemporaryClosedDatesRequestBody;
}

export interface RegisterFamilyPharmacistsRequest {
  postFamilyPharmacistsRequestBody: PostFamilyPharmacistsRequestBody;
}

export interface RegisterPharmacyListImageRequest {
  pharmacyId: number;
  postPharmacyListImagesRequestBody: PostPharmacyListImagesRequestBody;
}

export interface SearchPharmacyRequest {
  id?: Array<number>;
  chainId?: number;
  chain?: boolean;
  prefCode?: SearchPharmacyPrefCodeEnum;
  cityCode?: string;
  key?: string;
  latitude?: number;
  longitude?: number;
  isParking?: boolean;
  isTell?: boolean;
  isOnline?: boolean;
  isDispensing?: boolean;
  isOnlinePayment?: boolean;
  isPrescription?: boolean;
  isDelivery?: boolean;
  isCredit?: boolean;
  isElectronicMoney?: boolean;
  isQrPayment?: boolean;
  isScreening?: boolean;
  visibility?: Array<SearchPharmacyVisibilityEnum>;
  externalId?: string;
  limit?: number;
  offset?: number;
}

export interface SearchPharmacyCsvRequest {
  key?: string;
  latitude?: number;
  longitude?: number;
  isParking?: boolean;
  isTell?: boolean;
  isOnline?: boolean;
  isDispensing?: boolean;
  isOnlinePayment?: boolean;
  isPrescription?: boolean;
  isDelivery?: boolean;
  isCredit?: boolean;
  isElectronicMoney?: boolean;
  isQrPayment?: boolean;
  isScreening?: boolean;
  id?: Array<number>;
  chainId?: number;
  limit?: number;
  offset?: number;
}

export interface UpdatePharmaciesRequest {
  patchPharmaciesRequestBody: PatchPharmaciesRequestBody;
}

export interface UpdatePharmacyRequest {
  pharmacyId: number;
  pharmacyPharmacyUpdatable: PharmacyPharmacyUpdatable;
}

export interface UpdatePharmacyAllImagesCSVRequest {
  body?: Blob;
}

export interface UpdatePharmacyCrowdStatusRequest {
  pharmacyId: number;
  pharmacyPharmacyCrowdStatus: PharmacyPharmacyCrowdStatus;
}

export interface UpdatePharmacyFollowUpNotificationPatternRequest {
  pharmacyId: number;
  id: number;
  pharmacyFollowUpNotificationPatternUpdatable: PharmacyFollowUpNotificationPatternUpdatable;
}

export interface UpdatePharmacyFollowUpQuestionnairePatternRequest {
  pharmacyId: number;
  id: number;
  pharmacyFollowUpQuestionnairePatternUpdatable: PharmacyFollowUpQuestionnairePatternUpdatable;
}

export interface UpdatePharmacyImageRequest {
  pharmacyId: number;
  putPharmacyLogoImageRequestBody: PutPharmacyLogoImageRequestBody;
}

export interface UpdatePharmacyListImageRequest {
  pharmacyId: number;
  patchPharmacyListImagesRequestBody: PatchPharmacyListImagesRequestBody;
}

/**
 *
 */
export class PharmacyApi extends runtime.BaseAPI {
  /**
   * Register pharmacy
   */
  async createPharmacyRaw(
    requestParameters: CreatePharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacy>>> {
    if (
      requestParameters.pharmacyPharmacyRegistrable === null ||
      requestParameters.pharmacyPharmacyRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyRegistrable",
        "Required parameter requestParameters.pharmacyPharmacyRegistrable was null or undefined when calling createPharmacy."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyPharmacyRegistrable.map(PharmacyPharmacyRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyFromJSON));
  }

  /**
   * Register pharmacy
   */
  async createPharmacy(
    requestParameters: CreatePharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacy>> {
    return promiseRetry(
      (retry) =>
        this.createPharmacyRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register pharmacy with the csv interface
   */
  async createPharmacyCsvRaw(
    requestParameters: CreatePharmacyCsvRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacy>>> {
    if (requestParameters.body === null || requestParameters.body === undefined) {
      throw new runtime.RequiredError(
        "body",
        "Required parameter requestParameters.body was null or undefined when calling createPharmacyCsv."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "text/csv";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies.csv`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyFromJSON));
  }

  /**
   * Register pharmacy with the csv interface
   */
  async createPharmacyCsv(
    requestParameters: CreatePharmacyCsvRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacy>> {
    return promiseRetry(
      (retry) =>
        this.createPharmacyCsvRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete family pharmacist
   */
  async deleteFamilyPharmacistRaw(
    requestParameters: DeleteFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.familyPharmacistId === null || requestParameters.familyPharmacistId === undefined) {
      throw new runtime.RequiredError(
        "familyPharmacistId",
        "Required parameter requestParameters.familyPharmacistId was null or undefined when calling deleteFamilyPharmacist."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacists/{family_pharmacist_id}`.replace(
          `{${"family_pharmacist_id"}}`,
          encodeURIComponent(String(requestParameters.familyPharmacistId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete family pharmacist
   */
  async deleteFamilyPharmacist(
    requestParameters: DeleteFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteFamilyPharmacistRaw(requestParameters, initOverrides);
  }

  /**
   * Delete pharmacy follow up notification patterns
   */
  async deletePharmacyFollowUpNotificationPatternRaw(
    requestParameters: DeletePharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deletePharmacyFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deletePharmacyFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete pharmacy follow up notification patterns
   */
  async deletePharmacyFollowUpNotificationPattern(
    requestParameters: DeletePharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deletePharmacyFollowUpNotificationPatternRaw(requestParameters, initOverrides);
  }

  /**
   * Delete pharmacy follow up questionnaire patterns
   */
  async deletePharmacyFollowUpQuestionnairePatternRaw(
    requestParameters: DeletePharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deletePharmacyFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deletePharmacyFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete pharmacy follow up questionnaire patterns
   */
  async deletePharmacyFollowUpQuestionnairePattern(
    requestParameters: DeletePharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deletePharmacyFollowUpQuestionnairePatternRaw(requestParameters, initOverrides);
  }

  /**
   * Delete pharmacy list images
   */
  async deletePharmacyListImageRaw(
    requestParameters: DeletePharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deletePharmacyListImage."
      );
    }

    if (requestParameters.listImageId === null || requestParameters.listImageId === undefined) {
      throw new runtime.RequiredError(
        "listImageId",
        "Required parameter requestParameters.listImageId was null or undefined when calling deletePharmacyListImage."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.listImageId) {
      queryParameters["list_image_id"] = requestParameters.listImageId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/list-images`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete pharmacy list images
   */
  async deletePharmacyListImage(
    requestParameters: DeletePharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deletePharmacyListImageRaw(requestParameters, initOverrides);
  }

  /**
   * Get family pharmacist
   */
  async getFamilyPharmacistRaw(
    requestParameters: GetFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFamilyPharmacist>> {
    if (requestParameters.familyPharmacistId === null || requestParameters.familyPharmacistId === undefined) {
      throw new runtime.RequiredError(
        "familyPharmacistId",
        "Required parameter requestParameters.familyPharmacistId was null or undefined when calling getFamilyPharmacist."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacists/{family_pharmacist_id}`.replace(
          `{${"family_pharmacist_id"}}`,
          encodeURIComponent(String(requestParameters.familyPharmacistId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFamilyPharmacistFromJSON(jsonValue));
  }

  /**
   * Get family pharmacist
   */
  async getFamilyPharmacist(
    requestParameters: GetFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFamilyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.getFamilyPharmacistRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get family pharmacists by auth_id from JWT
   */
  async getFamilyPharmacistsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyFamilyPharmacist>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacists`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyFamilyPharmacistFromJSON));
  }

  /**
   * Get family pharmacists by auth_id from JWT
   */
  async getFamilyPharmacists(initOverrides?: RequestInit): Promise<Array<PharmacyFamilyPharmacist>> {
    return promiseRetry(
      (retry) =>
        this.getFamilyPharmacistsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Pharmacy
   */
  async getPharmacyRaw(
    requestParameters: GetPharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacy>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacy."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyFromJSON(jsonValue));
  }

  /**
   * Get Pharmacy
   */
  async getPharmacy(requestParameters: GetPharmacyRequest, initOverrides?: RequestInit): Promise<PharmacyPharmacy> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Pharmacy allow cidrs
   */
  async getPharmacyAllowCidrsRaw(
    requestParameters: GetPharmacyAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyAllowCidrs."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/allow-cidrs`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Get Pharmacy allow cidrs
   */
  async getPharmacyAllowCidrs(
    requestParameters: GetPharmacyAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<string>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyAllowCidrsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy crowd status setting
   */
  async getPharmacyCrowdStatusRaw(
    requestParameters: GetPharmacyCrowdStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacyCrowdStatus>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyCrowdStatus."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/crowd-status`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyCrowdStatusFromJSON(jsonValue));
  }

  /**
   * Get pharmacy crowd status setting
   */
  async getPharmacyCrowdStatus(
    requestParameters: GetPharmacyCrowdStatusRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacyCrowdStatus> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyCrowdStatusRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy follow up notification pattern
   */
  async getPharmacyFollowUpNotificationPatternRaw(
    requestParameters: GetPharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getPharmacyFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Get pharmacy follow up notification pattern
   */
  async getPharmacyFollowUpNotificationPattern(
    requestParameters: GetPharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy follow up notification patterns
   */
  async getPharmacyFollowUpNotificationPatternsRaw(
    requestParameters: GetPharmacyFollowUpNotificationPatternsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyFollowUpNotificationPattern>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyFollowUpNotificationPatterns."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-notification-patterns`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PharmacyFollowUpNotificationPatternFromJSON)
    );
  }

  /**
   * Get pharmacy follow up notification patterns
   */
  async getPharmacyFollowUpNotificationPatterns(
    requestParameters: GetPharmacyFollowUpNotificationPatternsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyFollowUpNotificationPattern>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyFollowUpNotificationPatternsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy follow up questionnaire pattern
   */
  async getPharmacyFollowUpQuestionnairePatternRaw(
    requestParameters: GetPharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getPharmacyFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Get pharmacy follow up questionnaire pattern
   */
  async getPharmacyFollowUpQuestionnairePattern(
    requestParameters: GetPharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy follow up questionnaire patterns
   */
  async getPharmacyFollowUpQuestionnairePatternsRaw(
    requestParameters: GetPharmacyFollowUpQuestionnairePatternsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyFollowUpQuestionnairePattern>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyFollowUpQuestionnairePatterns."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-questionnaire-patterns`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PharmacyFollowUpQuestionnairePatternFromJSON)
    );
  }

  /**
   * Get pharmacy follow up questionnaire patterns
   */
  async getPharmacyFollowUpQuestionnairePatterns(
    requestParameters: GetPharmacyFollowUpQuestionnairePatternsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyFollowUpQuestionnairePattern>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyFollowUpQuestionnairePatternsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * [Deprecated] Get counseling closed dates
   */
  async getPharmacyTemporaryClosedDatesRaw(
    requestParameters: GetPharmacyTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyTemporaryClosedDates>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyTemporaryClosedDates."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getPharmacyTemporaryClosedDates."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getPharmacyTemporaryClosedDates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/temporary-closed-dates`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyTemporaryClosedDatesFromJSON(jsonValue));
  }

  /**
   * [Deprecated] Get counseling closed dates
   */
  async getPharmacyTemporaryClosedDates(
    requestParameters: GetPharmacyTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyTemporaryClosedDates> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyTemporaryClosedDatesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get pharmacy usage summaries
   */
  async getPharmacyUsageSummariesRaw(
    requestParameters: GetPharmacyUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacyUsageSummary>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getPharmacyUsageSummaries."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getPharmacyUsageSummaries."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getPharmacyUsageSummaries."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-usage-summaries`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyUsageSummaryFromJSON));
  }

  /**
   * Get pharmacy usage summaries
   */
  async getPharmacyUsageSummaries(
    requestParameters: GetPharmacyUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacyUsageSummary>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyUsageSummariesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update family pharmacist
   */
  async patchFamilyPharmacistRaw(
    requestParameters: PatchFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFamilyPharmacist>> {
    if (requestParameters.familyPharmacistId === null || requestParameters.familyPharmacistId === undefined) {
      throw new runtime.RequiredError(
        "familyPharmacistId",
        "Required parameter requestParameters.familyPharmacistId was null or undefined when calling patchFamilyPharmacist."
      );
    }

    if (
      requestParameters.patchFamilyPharmacistRequestBody === null ||
      requestParameters.patchFamilyPharmacistRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchFamilyPharmacistRequestBody",
        "Required parameter requestParameters.patchFamilyPharmacistRequestBody was null or undefined when calling patchFamilyPharmacist."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacists/{family_pharmacist_id}`.replace(
          `{${"family_pharmacist_id"}}`,
          encodeURIComponent(String(requestParameters.familyPharmacistId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchFamilyPharmacistRequestBodyToJSON(requestParameters.patchFamilyPharmacistRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFamilyPharmacistFromJSON(jsonValue));
  }

  /**
   * Update family pharmacist
   */
  async patchFamilyPharmacist(
    requestParameters: PatchFamilyPharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFamilyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.patchFamilyPharmacistRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update Pharmacy allow cidrs
   */
  async patchPharmacyAllowCidrsRaw(
    requestParameters: PatchPharmacyAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling patchPharmacyAllowCidrs."
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling patchPharmacyAllowCidrs."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/allow-cidrs`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Update Pharmacy allow cidrs
   */
  async patchPharmacyAllowCidrs(
    requestParameters: PatchPharmacyAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<string>> {
    return promiseRetry(
      (retry) =>
        this.patchPharmacyAllowCidrsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy business hours
   */
  async patchPharmacyOpeningHoursRaw(
    requestParameters: PatchPharmacyOpeningHoursRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacyBusinessHours>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling patchPharmacyOpeningHours."
      );
    }

    if (
      requestParameters.pharmacyPharmacyBusinessHoursUpdatable === null ||
      requestParameters.pharmacyPharmacyBusinessHoursUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyBusinessHoursUpdatable",
        "Required parameter requestParameters.pharmacyPharmacyBusinessHoursUpdatable was null or undefined when calling patchPharmacyOpeningHours."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/business-hours`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyPharmacyBusinessHoursUpdatableToJSON(requestParameters.pharmacyPharmacyBusinessHoursUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyBusinessHoursFromJSON(jsonValue));
  }

  /**
   * Update pharmacy business hours
   */
  async patchPharmacyOpeningHours(
    requestParameters: PatchPharmacyOpeningHoursRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacyBusinessHours> {
    return promiseRetry(
      (retry) =>
        this.patchPharmacyOpeningHoursRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register pharmacy follow up notification patterns
   */
  async postPharmacyFollowUpNotificationPatternRaw(
    requestParameters: PostPharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling postPharmacyFollowUpNotificationPattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpNotificationPatternRegistrable === null ||
      requestParameters.pharmacyFollowUpNotificationPatternRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpNotificationPatternRegistrable",
        "Required parameter requestParameters.pharmacyFollowUpNotificationPatternRegistrable was null or undefined when calling postPharmacyFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-notification-patterns`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpNotificationPatternRegistrableToJSON(
          requestParameters.pharmacyFollowUpNotificationPatternRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Register pharmacy follow up notification patterns
   */
  async postPharmacyFollowUpNotificationPattern(
    requestParameters: PostPharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.postPharmacyFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register pharmacy follow up questionnaire patterns
   */
  async postPharmacyFollowUpQuestionnairePatternRaw(
    requestParameters: PostPharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling postPharmacyFollowUpQuestionnairePattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable === null ||
      requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpQuestionnairePatternRegistrable",
        "Required parameter requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable was null or undefined when calling postPharmacyFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-questionnaire-patterns`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpQuestionnairePatternRegistrableToJSON(
          requestParameters.pharmacyFollowUpQuestionnairePatternRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Register pharmacy follow up questionnaire patterns
   */
  async postPharmacyFollowUpQuestionnairePattern(
    requestParameters: PostPharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.postPharmacyFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * [Deprecated] Register and delete counseling closed dates
   */
  async putPharmacyTemporaryClosedDatesRaw(
    requestParameters: PutPharmacyTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling putPharmacyTemporaryClosedDates."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling putPharmacyTemporaryClosedDates."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling putPharmacyTemporaryClosedDates."
      );
    }

    if (
      requestParameters.putTemporaryClosedDatesRequestBody === null ||
      requestParameters.putTemporaryClosedDatesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putTemporaryClosedDatesRequestBody",
        "Required parameter requestParameters.putTemporaryClosedDatesRequestBody was null or undefined when calling putPharmacyTemporaryClosedDates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/temporary-closed-dates`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutTemporaryClosedDatesRequestBodyToJSON(requestParameters.putTemporaryClosedDatesRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * [Deprecated] Register and delete counseling closed dates
   */
  async putPharmacyTemporaryClosedDates(
    requestParameters: PutPharmacyTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putPharmacyTemporaryClosedDatesRaw(requestParameters, initOverrides);
  }

  /**
   * Register family pharmacists by auth_id from JWT
   */
  async registerFamilyPharmacistsRaw(
    requestParameters: RegisterFamilyPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFamilyPharmacist>> {
    if (
      requestParameters.postFamilyPharmacistsRequestBody === null ||
      requestParameters.postFamilyPharmacistsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postFamilyPharmacistsRequestBody",
        "Required parameter requestParameters.postFamilyPharmacistsRequestBody was null or undefined when calling registerFamilyPharmacists."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacists`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostFamilyPharmacistsRequestBodyToJSON(requestParameters.postFamilyPharmacistsRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFamilyPharmacistFromJSON(jsonValue));
  }

  /**
   * Register family pharmacists by auth_id from JWT
   */
  async registerFamilyPharmacists(
    requestParameters: RegisterFamilyPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFamilyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.registerFamilyPharmacistsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register pharmacy list images
   */
  async registerPharmacyListImageRaw(
    requestParameters: RegisterPharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostPharmacyListImagesResponse>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling registerPharmacyListImage."
      );
    }

    if (
      requestParameters.postPharmacyListImagesRequestBody === null ||
      requestParameters.postPharmacyListImagesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postPharmacyListImagesRequestBody",
        "Required parameter requestParameters.postPharmacyListImagesRequestBody was null or undefined when calling registerPharmacyListImage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/list-images`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPharmacyListImagesRequestBodyToJSON(requestParameters.postPharmacyListImagesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostPharmacyListImagesResponseFromJSON(jsonValue));
  }

  /**
   * Register pharmacy list images
   */
  async registerPharmacyListImage(
    requestParameters: RegisterPharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<PostPharmacyListImagesResponse> {
    return promiseRetry(
      (retry) =>
        this.registerPharmacyListImageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search pharmacies. Either the id or chain_id or chain or pref_code or key or the latitude and longitude are required.
   */
  async searchPharmacyRaw(
    requestParameters: SearchPharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacies>> {
    const queryParameters: any = {};

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.chain !== undefined) {
      queryParameters["chain"] = requestParameters.chain;
    }

    if (requestParameters.prefCode !== undefined) {
      queryParameters["pref_code"] = requestParameters.prefCode;
    }

    if (requestParameters.cityCode !== undefined) {
      queryParameters["city_code"] = requestParameters.cityCode;
    }

    if (requestParameters.key !== undefined) {
      queryParameters["key"] = requestParameters.key;
    }

    if (requestParameters.latitude !== undefined) {
      queryParameters["latitude"] = requestParameters.latitude;
    }

    if (requestParameters.longitude !== undefined) {
      queryParameters["longitude"] = requestParameters.longitude;
    }

    if (requestParameters.isParking !== undefined) {
      queryParameters["is_parking"] = requestParameters.isParking;
    }

    if (requestParameters.isTell !== undefined) {
      queryParameters["is_tell"] = requestParameters.isTell;
    }

    if (requestParameters.isOnline !== undefined) {
      queryParameters["is_online"] = requestParameters.isOnline;
    }

    if (requestParameters.isDispensing !== undefined) {
      queryParameters["is_dispensing"] = requestParameters.isDispensing;
    }

    if (requestParameters.isOnlinePayment !== undefined) {
      queryParameters["is_online_payment"] = requestParameters.isOnlinePayment;
    }

    if (requestParameters.isPrescription !== undefined) {
      queryParameters["is_prescription"] = requestParameters.isPrescription;
    }

    if (requestParameters.isDelivery !== undefined) {
      queryParameters["is_delivery"] = requestParameters.isDelivery;
    }

    if (requestParameters.isCredit !== undefined) {
      queryParameters["is_credit"] = requestParameters.isCredit;
    }

    if (requestParameters.isElectronicMoney !== undefined) {
      queryParameters["is_electronic_money"] = requestParameters.isElectronicMoney;
    }

    if (requestParameters.isQrPayment !== undefined) {
      queryParameters["is_qr_payment"] = requestParameters.isQrPayment;
    }

    if (requestParameters.isScreening !== undefined) {
      queryParameters["is_screening"] = requestParameters.isScreening;
    }

    if (requestParameters.visibility) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    if (requestParameters.externalId !== undefined) {
      queryParameters["external_id"] = requestParameters.externalId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/pharmacies`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmaciesFromJSON(jsonValue));
  }

  /**
   * Search pharmacies. Either the id or chain_id or chain or pref_code or key or the latitude and longitude are required.
   */
  async searchPharmacy(
    requestParameters: SearchPharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacies> {
    return promiseRetry(
      (retry) =>
        this.searchPharmacyRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search pharmacy and respond with csv interface
   */
  async searchPharmacyCsvRaw(
    requestParameters: SearchPharmacyCsvRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<string>> {
    const queryParameters: any = {};

    if (requestParameters.key !== undefined) {
      queryParameters["key"] = requestParameters.key;
    }

    if (requestParameters.latitude !== undefined) {
      queryParameters["latitude"] = requestParameters.latitude;
    }

    if (requestParameters.longitude !== undefined) {
      queryParameters["longitude"] = requestParameters.longitude;
    }

    if (requestParameters.isParking !== undefined) {
      queryParameters["is_parking"] = requestParameters.isParking;
    }

    if (requestParameters.isTell !== undefined) {
      queryParameters["is_tell"] = requestParameters.isTell;
    }

    if (requestParameters.isOnline !== undefined) {
      queryParameters["is_online"] = requestParameters.isOnline;
    }

    if (requestParameters.isDispensing !== undefined) {
      queryParameters["is_dispensing"] = requestParameters.isDispensing;
    }

    if (requestParameters.isOnlinePayment !== undefined) {
      queryParameters["is_online_payment"] = requestParameters.isOnlinePayment;
    }

    if (requestParameters.isPrescription !== undefined) {
      queryParameters["is_prescription"] = requestParameters.isPrescription;
    }

    if (requestParameters.isDelivery !== undefined) {
      queryParameters["is_delivery"] = requestParameters.isDelivery;
    }

    if (requestParameters.isCredit !== undefined) {
      queryParameters["is_credit"] = requestParameters.isCredit;
    }

    if (requestParameters.isElectronicMoney !== undefined) {
      queryParameters["is_electronic_money"] = requestParameters.isElectronicMoney;
    }

    if (requestParameters.isQrPayment !== undefined) {
      queryParameters["is_qr_payment"] = requestParameters.isQrPayment;
    }

    if (requestParameters.isScreening !== undefined) {
      queryParameters["is_screening"] = requestParameters.isScreening;
    }

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies.csv`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Search pharmacy and respond with csv interface
   */
  async searchPharmacyCsv(requestParameters: SearchPharmacyCsvRequest, initOverrides?: RequestInit): Promise<string> {
    return promiseRetry(
      (retry) =>
        this.searchPharmacyCsvRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update multiple pharmacies
   */
  async updatePharmaciesRaw(
    requestParameters: UpdatePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacy>>> {
    if (
      requestParameters.patchPharmaciesRequestBody === null ||
      requestParameters.patchPharmaciesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchPharmaciesRequestBody",
        "Required parameter requestParameters.patchPharmaciesRequestBody was null or undefined when calling updatePharmacies."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchPharmaciesRequestBodyToJSON(requestParameters.patchPharmaciesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyFromJSON));
  }

  /**
   * Update multiple pharmacies
   */
  async updatePharmacies(
    requestParameters: UpdatePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacy>> {
    return promiseRetry(
      (retry) =>
        this.updatePharmaciesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy
   */
  async updatePharmacyRaw(
    requestParameters: UpdatePharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacy>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacy."
      );
    }

    if (
      requestParameters.pharmacyPharmacyUpdatable === null ||
      requestParameters.pharmacyPharmacyUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyUpdatable",
        "Required parameter requestParameters.pharmacyPharmacyUpdatable was null or undefined when calling updatePharmacy."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyPharmacyUpdatableToJSON(requestParameters.pharmacyPharmacyUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyFromJSON(jsonValue));
  }

  /**
   * Update pharmacy
   */
  async updatePharmacy(
    requestParameters: UpdatePharmacyRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacy> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update all images with the content in the zip file
   */
  async updatePharmacyAllImagesCSVRaw(
    requestParameters: UpdatePharmacyAllImagesCSVRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacy>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/zip";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-images.zip`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyFromJSON));
  }

  /**
   * Update all images with the content in the zip file
   */
  async updatePharmacyAllImagesCSV(
    requestParameters: UpdatePharmacyAllImagesCSVRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacy>> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyAllImagesCSVRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy crowd status setting
   */
  async updatePharmacyCrowdStatusRaw(
    requestParameters: UpdatePharmacyCrowdStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacyCrowdStatus>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacyCrowdStatus."
      );
    }

    if (
      requestParameters.pharmacyPharmacyCrowdStatus === null ||
      requestParameters.pharmacyPharmacyCrowdStatus === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyCrowdStatus",
        "Required parameter requestParameters.pharmacyPharmacyCrowdStatus was null or undefined when calling updatePharmacyCrowdStatus."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/crowd-status`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyPharmacyCrowdStatusToJSON(requestParameters.pharmacyPharmacyCrowdStatus),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyCrowdStatusFromJSON(jsonValue));
  }

  /**
   * Update pharmacy crowd status setting
   */
  async updatePharmacyCrowdStatus(
    requestParameters: UpdatePharmacyCrowdStatusRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacyCrowdStatus> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyCrowdStatusRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy follow up notification patterns
   */
  async updatePharmacyFollowUpNotificationPatternRaw(
    requestParameters: UpdatePharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpNotificationPattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacyFollowUpNotificationPattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updatePharmacyFollowUpNotificationPattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpNotificationPatternUpdatable === null ||
      requestParameters.pharmacyFollowUpNotificationPatternUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpNotificationPatternUpdatable",
        "Required parameter requestParameters.pharmacyFollowUpNotificationPatternUpdatable was null or undefined when calling updatePharmacyFollowUpNotificationPattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-notification-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpNotificationPatternUpdatableToJSON(
          requestParameters.pharmacyFollowUpNotificationPatternUpdatable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFollowUpNotificationPatternFromJSON(jsonValue));
  }

  /**
   * Update pharmacy follow up notification patterns
   */
  async updatePharmacyFollowUpNotificationPattern(
    requestParameters: UpdatePharmacyFollowUpNotificationPatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpNotificationPattern> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyFollowUpNotificationPatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy follow up questionnaire patterns
   */
  async updatePharmacyFollowUpQuestionnairePatternRaw(
    requestParameters: UpdatePharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyFollowUpQuestionnairePattern>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacyFollowUpQuestionnairePattern."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updatePharmacyFollowUpQuestionnairePattern."
      );
    }

    if (
      requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable === null ||
      requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyFollowUpQuestionnairePatternUpdatable",
        "Required parameter requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable was null or undefined when calling updatePharmacyFollowUpQuestionnairePattern."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/follow-up-questionnaire-patterns/{id}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyFollowUpQuestionnairePatternUpdatableToJSON(
          requestParameters.pharmacyFollowUpQuestionnairePatternUpdatable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyFollowUpQuestionnairePatternFromJSON(jsonValue)
    );
  }

  /**
   * Update pharmacy follow up questionnaire patterns
   */
  async updatePharmacyFollowUpQuestionnairePattern(
    requestParameters: UpdatePharmacyFollowUpQuestionnairePatternRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyFollowUpQuestionnairePattern> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyFollowUpQuestionnairePatternRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy logo image
   */
  async updatePharmacyImageRaw(
    requestParameters: UpdatePharmacyImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutPharmacyLogoImageResponse>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacyImage."
      );
    }

    if (
      requestParameters.putPharmacyLogoImageRequestBody === null ||
      requestParameters.putPharmacyLogoImageRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPharmacyLogoImageRequestBody",
        "Required parameter requestParameters.putPharmacyLogoImageRequestBody was null or undefined when calling updatePharmacyImage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/logo-image`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPharmacyLogoImageRequestBodyToJSON(requestParameters.putPharmacyLogoImageRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutPharmacyLogoImageResponseFromJSON(jsonValue));
  }

  /**
   * Update pharmacy logo image
   */
  async updatePharmacyImage(
    requestParameters: UpdatePharmacyImageRequest,
    initOverrides?: RequestInit
  ): Promise<PutPharmacyLogoImageResponse> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyImageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy list images
   */
  async updatePharmacyListImageRaw(
    requestParameters: UpdatePharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatchPharmacyListImagesResponse>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling updatePharmacyListImage."
      );
    }

    if (
      requestParameters.patchPharmacyListImagesRequestBody === null ||
      requestParameters.patchPharmacyListImagesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchPharmacyListImagesRequestBody",
        "Required parameter requestParameters.patchPharmacyListImagesRequestBody was null or undefined when calling updatePharmacyListImage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/list-images`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchPharmacyListImagesRequestBodyToJSON(requestParameters.patchPharmacyListImagesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatchPharmacyListImagesResponseFromJSON(jsonValue));
  }

  /**
   * Update pharmacy list images
   */
  async updatePharmacyListImage(
    requestParameters: UpdatePharmacyListImageRequest,
    initOverrides?: RequestInit
  ): Promise<PatchPharmacyListImagesResponse> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyListImageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum SearchPharmacyPrefCodeEnum {
  _01 = "01",
  _02 = "02",
  _03 = "03",
  _04 = "04",
  _05 = "05",
  _06 = "06",
  _07 = "07",
  _08 = "08",
  _09 = "09",
  _10 = "10",
  _11 = "11",
  _12 = "12",
  _13 = "13",
  _14 = "14",
  _15 = "15",
  _16 = "16",
  _17 = "17",
  _18 = "18",
  _19 = "19",
  _20 = "20",
  _21 = "21",
  _22 = "22",
  _23 = "23",
  _24 = "24",
  _25 = "25",
  _26 = "26",
  _27 = "27",
  _28 = "28",
  _29 = "29",
  _30 = "30",
  _31 = "31",
  _32 = "32",
  _33 = "33",
  _34 = "34",
  _35 = "35",
  _36 = "36",
  _37 = "37",
  _38 = "38",
  _39 = "39",
  _40 = "40",
  _41 = "41",
  _42 = "42",
  _43 = "43",
  _44 = "44",
  _45 = "45",
  _46 = "46",
  _47 = "47",
}
/**
 * @export
 * @enum {string}
 */
export enum SearchPharmacyVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}
