/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyInterview,
  PharmacyInterviewFromJSON,
  PharmacyInterviewFromJSONTyped,
  PharmacyInterviewToJSON,
  PharmacyMedicineNotebookClient,
  PharmacyMedicineNotebookClientFromJSON,
  PharmacyMedicineNotebookClientFromJSONTyped,
  PharmacyMedicineNotebookClientToJSON,
  PharmacyOptionalParameter,
  PharmacyOptionalParameterFromJSON,
  PharmacyOptionalParameterFromJSONTyped,
  PharmacyOptionalParameterToJSON,
} from "./";

export const PharmacyChainTypename = "PharmacyChain" as const;

/**
 *
 * @export
 * @interface PharmacyChain
 */
export interface PharmacyChain {
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  appName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyChain
   */
  cutoffDay?: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  displayServiceNotification?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  fqdn?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyChain
   */
  id?: number;
  /**
   *
   * @type {PharmacyInterview}
   * @memberof PharmacyChain
   */
  interviewSheet?: PharmacyInterview;
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  introduceType?: PharmacyChainIntroduceTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  isExceededFollowUpCharLimit?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  isFollowUpOnly?: boolean;
  /**
   *
   * @type {PharmacyMedicineNotebookClient}
   * @memberof PharmacyChain
   */
  medicineNotebookClient?: PharmacyMedicineNotebookClient;
  /**
   *
   * @type {object}
   * @memberof PharmacyChain
   */
  messageProviderAuthentication?: object;
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  note?: string;
  /**
   *
   * @type {Array<PharmacyOptionalParameter>}
   * @memberof PharmacyChain
   */
  optionalParameters?: Array<PharmacyOptionalParameter>;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  requiredChildWeight?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  requiredDateOfBirth?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  usableChildWeight?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  usableMedicineNotebook?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  usableOldPricing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyChain
   */
  usableRefill?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyChain
   */
  usageInterviewCounselingTypes?: Array<PharmacyChainUsageInterviewCounselingTypesEnum>;
  /**
   *
   * @type {string}
   * @memberof PharmacyChain
   */
  usageInterviewSheet?: PharmacyChainUsageInterviewSheetEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyChain
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyChainIntroduceTypeEnum {
  Web = "web",
  Line = "line",
  App = "app",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyChainUsageInterviewCounselingTypesEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyChainUsageInterviewSheetEnum {
  Default = "default",
  Custom = "custom",
  Unused = "unused",
}

export function PharmacyChainFromJSON(json: any): PharmacyChain {
  return PharmacyChainFromJSONTyped(json, false);
}

export function PharmacyChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyChain {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyChainTypename,

    appName: !exists(json, "app_name") ? undefined : json["app_name"],
    cutoffDay: !exists(json, "cutoff_day") ? undefined : json["cutoff_day"],
    displayServiceNotification: !exists(json, "display_service_notification")
      ? undefined
      : json["display_service_notification"],
    fqdn: !exists(json, "fqdn") ? undefined : json["fqdn"],
    id: !exists(json, "id") ? undefined : json["id"],
    interviewSheet: !exists(json, "interview_sheet") ? undefined : PharmacyInterviewFromJSON(json["interview_sheet"]),
    introduceType: !exists(json, "introduce_type") ? undefined : json["introduce_type"],
    isExceededFollowUpCharLimit: !exists(json, "is_exceeded_follow_up_char_limit")
      ? undefined
      : json["is_exceeded_follow_up_char_limit"],
    isFollowUpOnly: !exists(json, "is_follow_up_only") ? undefined : json["is_follow_up_only"],
    medicineNotebookClient: !exists(json, "medicine_notebook_client")
      ? undefined
      : PharmacyMedicineNotebookClientFromJSON(json["medicine_notebook_client"]),
    messageProviderAuthentication: !exists(json, "message_provider_authentication")
      ? undefined
      : json["message_provider_authentication"],
    name: !exists(json, "name") ? undefined : json["name"],
    note: !exists(json, "note") ? undefined : json["note"],
    optionalParameters: !exists(json, "optional_parameters")
      ? undefined
      : (json["optional_parameters"] as Array<any>).map(PharmacyOptionalParameterFromJSON),
    requiredChildWeight: !exists(json, "required_child_weight") ? undefined : json["required_child_weight"],
    requiredDateOfBirth: !exists(json, "required_date_of_birth") ? undefined : json["required_date_of_birth"],
    usableChildWeight: !exists(json, "usable_child_weight") ? undefined : json["usable_child_weight"],
    usableMedicineNotebook: !exists(json, "usable_medicine_notebook") ? undefined : json["usable_medicine_notebook"],
    usableOldPricing: !exists(json, "usable_old_pricing") ? undefined : json["usable_old_pricing"],
    usableRefill: !exists(json, "usable_refill") ? undefined : json["usable_refill"],
    usageInterviewCounselingTypes: !exists(json, "usage_interview_counseling_types")
      ? undefined
      : json["usage_interview_counseling_types"],
    usageInterviewSheet: !exists(json, "usage_interview_sheet") ? undefined : json["usage_interview_sheet"],
  };
}

export function PharmacyChainToJSON(object?: PharmacyChain | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    app_name: value.appName,
    cutoff_day: value.cutoffDay,
    display_service_notification: value.displayServiceNotification,
    fqdn: value.fqdn,
    id: value.id,
    interview_sheet: PharmacyInterviewToJSON(value.interviewSheet),
    introduce_type: value.introduceType,
    is_exceeded_follow_up_char_limit: value.isExceededFollowUpCharLimit,
    is_follow_up_only: value.isFollowUpOnly,
    medicine_notebook_client: PharmacyMedicineNotebookClientToJSON(value.medicineNotebookClient),
    message_provider_authentication: value.messageProviderAuthentication,
    name: value.name,
    note: value.note,
    optional_parameters:
      value.optionalParameters === undefined
        ? undefined
        : (value.optionalParameters as Array<any>).map(PharmacyOptionalParameterToJSON),
    required_child_weight: value.requiredChildWeight,
    required_date_of_birth: value.requiredDateOfBirth,
    usable_child_weight: value.usableChildWeight,
    usable_medicine_notebook: value.usableMedicineNotebook,
    usable_old_pricing: value.usableOldPricing,
    usable_refill: value.usableRefill,
    usage_interview_counseling_types: value.usageInterviewCounselingTypes,
    usage_interview_sheet: value.usageInterviewSheet,
  };
}
