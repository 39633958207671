import { createFetchTimeout } from "@mgdx/api/lib/createFetchTimeout";
import { Configuration, LinkedPatientApi } from "@mgdx/api/lib/patient/patient";
import requestHeaders from "@mgdx/api/lib/requestHeaders";
import { apiErrorHandler, errorHandlerReport } from "@mgdx-libs/error-handler";
import { forceRefreshAccessToken, getAccessToken } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import { Dispatch } from "redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";
import { createCustomTokenUser } from "../../hooks/useInitializeLiff";

// memo: Cookieの利用のため、pathをyqbに変更
export const linkedPatientWithCookieApi = new LinkedPatientApi(
  new Configuration({
    basePath: process.env.MGDX_YQB_URL + "/api-patient",
    credentials: "include",
    accessToken: getAccessToken,
    headers: { ...requestHeaders, ...(process.env.MGDX_API_KEY && { "X-MGDX-API-KEY": process.env.MGDX_API_KEY }) },
    fetchApi: createFetchTimeout(60000),
  })
);

export const createNewPatientWithMccmOidcFirebaseUser = async (dispatch: Dispatch) => {
  const response = await linkedPatientWithCookieApi
    .postLinkedPatients({
      postLinkedPatientRequestBody: {},
    })
    .catch(async (errorOrResponse) => {
      if (errorOrResponse instanceof Response) {
        const apiError = await apiErrorHandler(errorOrResponse);
        errorHandlerReport(apiError);
        throw apiError;
      } else if (errorOrResponse instanceof Error) {
        await errorHandlerReport(errorOrResponse);
        throw errorOrResponse;
      }
      throw errorOrResponse;
    });

  const { patient } = response;
  logger.debug("patient: %o", patient);

  if (!patient) {
    logger.error("patient is undefined");
    throw Error("会員登録に失敗しました。");
  }

  await forceRefreshAccessToken();
  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};

export const linkExistingPatientWithMccmOidcFirebaseUser = async (
  dispatch: Dispatch,
  { code, state }: { code?: string; state?: string }
) => {
  const response = await linkedPatientWithCookieApi
    .postLinkedPatients({
      postLinkedPatientRequestBody: { linkUserToken: code },
      state,
    })
    .catch(async (errorOrResponse) => {
      if (errorOrResponse instanceof Response) {
        const apiError = await apiErrorHandler(errorOrResponse);
        errorHandlerReport(apiError);
        throw apiError;
      } else if (errorOrResponse instanceof Error) {
        await errorHandlerReport(errorOrResponse);
        throw errorOrResponse;
      }
      throw errorOrResponse;
    });

  const { patient, customToken } = response;
  logger.debug("patient: %o", patient);

  if (!patient || !customToken) {
    logger.error("patient or customToken is undefined: patient=%o, customToken=%o", patient, customToken);
    throw Error("会員登録に失敗しました。");
  }

  await createCustomTokenUser(customToken);
  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};
